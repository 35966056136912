@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
  background-color: #f9fafb; /* Light gray background for a clean look */
  color: #333; /* Darker gray for better readability */
  line-height: 1.6; /* Improve readability */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; /* Make headings bold for better hierarchy */
}

p {
  font-weight: 400;
  margin-bottom: 1.5rem; /* Space between paragraphs for readability */
}

a {
  transition: color 0.3s ease;
}

a:hover {
  color: #1a73e8; /* Light blue color to enhance user experience */
}
